import { createRouter, createWebHashHistory } from 'vue-router'

const routesPC = [
  {
    path: '/',
    component: () => import('../views/layout/PcHome.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () =>
          import('../views/pc/Home.vue'),
        meta: {
          nav: 'home'
        }
      },
      {
        path: 'service',
        component: () => import('../views/pc/service/Index.vue'),
        redirect: '/service/inviteJob',
        children: [
          {
            path: 'inviteJob',
            name: 'inviteJob',
            component: () =>
              import('../views/pc/service/InviteJob.vue'),
            meta: {
              nav: 'inviteJob'
            }
          },
          {
            path: 'numBank',
            name: 'numBank',
            component: () =>
              import('../views/pc/service/NumBank.vue'),
            meta: {
              nav: 'numBank'
            }
          },
          {
            path: 'cloudSystem',
            name: 'cloudSystem',
            component: () =>
              import('../views/pc/service/CloudSystem.vue'),
            meta: {
              nav: 'cloudSystem'
            }
          },
          {
            path: 'labour',
            name: 'labour',
            component: () =>
              import('../views/pc/service/Labour.vue'),
            meta: {
              nav: 'labour'
            }
          }
        ]
      },
      {
        path: 'about',
        name: 'about',
        component: () =>
          import('../views/pc/About.vue'),
        meta: {
          nav: 'about'
        }
      }
    ]
  }
];
// const routesM = [
//   {
//     path: '/',
//     name: 'mobileWebsite',
//     component: () => import('../views/layout/MobileHome.vue'),
//   }
// ];

var routes = routesPC;
// let Adaptive = /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i

// if (navigator.userAgent.match(Adaptive)) {
//   routes = routesM
// } else {
//   routes = routesPC
// }

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;

  // 兼容IE
  window.scrollTo(0, 0);
  next();
})

export default router
